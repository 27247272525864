// aggrid specific styles


// wrap text in cells with rowheight=auto
/*.cell-wrap-text {
  white-space: normal !important;
}*/

// planstatus in ag-grid

.status_gray{
  background-color:#666666;
  color: white;
  font-weight: 500;
}

.status_white {
  background-color: $white;
  color: black;
  font-weight: 500;
}


.status_green {
  background-color: $green;
  color: white;
  font-weight: 500;
}

.status_red {
  background-color: $red;
  color: white;
  font-weight: 500;
}



.status_yellow {
  background-color: $yellow;
  color: white;
  font-weight: 500;
}

.status_blue {
  background-color: $blue;
  color: white;
  font-weight: 500;
}